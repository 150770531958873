// Import Custom Variables
@import "variables.scss";

// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Import Custom SCSS
@import "global.scss";

// Layout
@import "layout/authentication.scss";
@import "layout/dashboard-default.scss";
@import "layout/dashboard-fixed.scss";
@import "layout/error.scss";

// Navigation
@import "navigation/nav.scss";
@import "navigation/topnav.scss";
@import "navigation/sidenav/sidenav.scss";
@import "navigation/sidenav/sidenav-dark.scss";
@import "navigation/sidenav/sidenav-light.scss";


.custom-calendar {
  border-radius: 6px;
  width: 65%;
  padding: 15px;
  border-color: rgba(128, 128, 128, 0.4509803922);
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-end-date {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-left-15 {
  margin-left: 15px;
}

.pointer{
  cursor: pointer;
}

.my-custom-button {
	background-color: #0451a2;
  color: #ffffff;
	border-radius: 10px;
	font-size: 15px;
	border: none;
	display: inline-block;
	outline: 0;
	padding: 15px 15px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
}

.my-custom-button:hover {
  background-color: #034890;
}

.custom-input {
  border-radius: 6px;
  padding: 15px 15px;
  border-color: #80808073;
  border-width: 1px;
}

.custom-input:hover {
  border-color: black;
}

/* Absolute Center Spinner */
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.font-size-14 {
  font-size: 14px;
}

.font-size-17 {
  font-size: 17px;
}

.custom-color {
  color: #034890;
}

.dialog-close {
  //position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blue-color{
  color: #0451a2;
}

.black-color{
  color: black;
}

.dialog-header {
  border: 1px solid #e0e0e1;
}

.dialog-title {
  margin: 8px 15px !important;
}

.dialog-title-color {
  color: #033a72;
}

.custom-option{
  background-color: white;
  width: 210px;
}

.mat-table {
  overflow: auto;
  max-height: 500px;
}

.custom-margin{
  margin: 0 22px;
}

.custom-edit-margin{
  margin: 0 5px;
}

.red-color {
  color: #930202;
}

.red-color:hover {
  color: #660101;
}

.green-color {
  color: #006e06;
}

.green-color:hover {
  color: #004203;
}

.action-cell {
  align-items: center;
  display: flex;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


@media(max-width: 600px) {
  .custom-margin{
    margin: 0;
  }

  .margin-end-date {
    margin-left: 16px;
  }

  .mobile-button {
    width: 50%;
    margin-top: 15px;
  }
}